import React from "react";
import { Helmet } from "react-helmet";

export default () => (
  <Helmet>
    <meta charSet="utf-8" />
    <meta http-equiv="content-encoding" content="gzip; charset=UTF-8" />
    <title>Financial Support System for Youth Sports</title>
    <meta
      property="og:title"
      content="Financial Support System for Youth Sports"
    />
    <meta
      name="twitter:title"
      content="Financial Support System for Youth Sports"
    />

    <link rel="canonical" href="https://www.liftrocket.com" />
    <meta property="og:url" content="https://www.liftrocket.com" />
    <meta name="twitter:url" content="https://www.liftrocket.com" />
    <meta name="facebook-domain-verification" content="k4bb4cyqpxdlm4e88o11uoh6ow0uxq" />

    <meta
      name="description"
      content="The LiftRocket financing and spend management system helps families pay for registration, equipment, travel, training and more. Do you run a league, team, sports camp, or training center, or have a kid who plays? "
    />
    <meta
      property="og:description"
      content="The LiftRocket financing and spend management system helps families pay for registration, equipment, travel, training and more. Do you run a league, team, sports camp, or training center, or have a kid who plays? "
    />
    <meta
      name="twitter:description"
      content="The LiftRocket financing and spend management system helps families pay for registration, equipment, travel, training and more. Do you run a league, team, sports camp, or training center, or have a kid who plays? "
    />
    <meta property="fb:app_id" content="383520185612995" />
    <meta property="og:type" content="article" />

    <link
      rel="icon"
      href="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
    <meta
      property="og:image"
      content="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
    <meta
      name="twitter:image"
      content="https://s3.us-east-2.amazonaws.com/liftrocket.org/liftRocket.png"
    />
     <script type="text/javascript">
      {`
      (function(w, d){
        if (!w.rdt) {
          w.rdt = function() {
            w.rdt.sendEvent ? w.rdt.sendEvent.apply(w.rdt, arguments) : w.rdt.callQueue.push(arguments);
          };
          w.rdt.callQueue = [];

          var t = d.createElement('script');
          t.src = 'https://www.redditstatic.com/ads/pixel.js';
          t.async = true;

          var s = d.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(t, s);
    
          // These calls need to be part of the rdt function's call queue since the script is loaded asynchronously.
          // They will be processed once the script is loaded.
          w.rdt('init','t2_5knoea5u', {'optOut':false, 'useDecimalCurrencyValues':true});
          w.rdt('track', 'PageVisit');
        }
      })(window, document);
    `}
    </script>
  </Helmet>
);
